import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Dialog } from '../Dialog';
import { TaskForm } from '../DialogForms/TaskForm';

interface GuideDetailDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  taskId?: string;
}

export const TaskDialog: FC<GuideDetailDialogProps> = (props) => {
  const { onClose, onSubmit, taskId } = props;

  return (
    <Dialog title={taskId ? <Trans i18nKey="tasks.editTask" /> : <Trans i18nKey="tasks.newTask" />}
            onClose={onClose}>
      <TaskForm onSubmit={onSubmit} taskId={taskId} />
    </Dialog>
  );
};
