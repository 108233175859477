import MaterialTable, { Column } from '@material-table/core';
import { format } from 'date-fns';
import React, {
  forwardRef, useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tasks } from 'src/commonTypes';
import { UserContext } from 'src/contexts';
import { CustomTablePagination } from 'src/customStyledComponents';
import { useMaterialTableLocalization } from 'src/hooks';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Chip, Grid, Paper } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { RemoveRedEye } from '@mui/icons-material';
import { TaskDialog } from '../../components/Dialogs/TaskDialog';
import { TaskDetailDialog } from '../../components/Dialogs/TaskDetailDialog';
import { useSnackbar } from 'notistack';

interface TableState {
  columns: Array<Column<Tasks>>;
  data: Tasks[];
}

export interface TasksCardFunctions {
  refetch: () => void;
}

interface TasksCardProps {
  onRowClick?: (id: string) => void;
}

enum TaskStatus {
  OWN = 'own',
  UNASSIGNED = 'unassigned',
  ALL = 'all',
}

const TasksCard = forwardRef<TasksCardFunctions, TasksCardProps>((_, ref) => {
  const localization = useMaterialTableLocalization();
  const { userData } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [tableData, setTableData] = useState<TableState>({
    columns: [],
    data: [],
  });
  const { id } = useParams<{ id: string }>();

  const [tasksFilter, setTasksFilter] = useState<TaskStatus>(TaskStatus.ALL);

  const [openTaskForm, setOpenTaskForm] = useState<boolean | string>(false);
  const [openTaskDetail, setOpenTaskDetail] = useState<string>();

  const getTasks = useCallback(async () => {
    const res = await fetch(`/api/tasks/${id}?filter=${tasksFilter}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    }).then((data) => data.json());
    setTableData({ ...tableData, data: res });
    return res;
  }, [id, tasksFilter, userData, tableData]);

  useImperativeHandle(ref, () => ({
    refetch: () => getTasks(),
  }));

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksFilter]);


  const handleDelete = async (id: string) => {
    await fetch(`/api/tasks/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'DELETE',
    });
    enqueueSnackbar(<Trans i18nKey="labels.deleted" />, {
      variant: 'success',
    });

    getTasks();
  };


  const getUsersEmail = (userId?: string) => {
    const userEmail =
      userData?.usersData?.find((a) => a?._id === userId)?.email ?? '';
    return userEmail;
  };

  const dataTable: TableState = {
    columns: [
      {
        title: <Trans i18nKey="tables.tasksCard.name" />,
        field: 'name',
        render: (row) => {
          return <div>{row?.name || '--'}</div>;
        },
      },
      {
        title: <Trans i18nKey="tables.tasksCard.deadline" />,
        field: 'deadline',
        render: (row) => {
          const date = format(new Date(row?.deadline), 'dd.MM.yyyy');

          return <div>{date || '--'}</div>;
        },
      },
      {
        title: <Trans i18nKey="tables.tasksCard.assigneeName" />,
        field: 'assigneeName',
        render: (row) => {
          const userEmail = getUsersEmail(row.assignee);
          return <div>{userEmail || '--'}</div>;
        },
      },
      {
        title: <Trans i18nKey="tables.tasksCard.placementName" />,
        field: 'placementName',
        render: (row) => {
          return <div>{row?.placementNames || '--'}</div>;
        },
      },
    ],
    data: [],
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <Paper>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <Grid item sx={{ marginRight: '24px' }}>
            <Trans i18nKey="tasksCard.tasks" />
          </Grid>
          <Chip
            avatar={
              tasksFilter === TaskStatus.OWN ? (
                <DoneIcon color="error" />
              ) : undefined
            }
            label={<Trans i18nKey="tasksCard.myTasks" />}
            variant={tasksFilter === TaskStatus.OWN ? 'filled' : 'outlined'}
            color="primary"
            onClick={() => {
              if (tasksFilter === TaskStatus.OWN) {
                setTasksFilter(TaskStatus.ALL);
              } else {
                setTasksFilter(TaskStatus.OWN);
              }
            }}
            sx={{
              marginRight: '8px',
              borderRadius: '8px',
              '& .MuiChip-avatar': { backgroundColor: 'transparent' },
            }}
          />
          <Chip
            avatar={
              tasksFilter === TaskStatus.UNASSIGNED ? (
                <DoneIcon color="error" />
              ) : undefined
            }
            label={<Trans i18nKey="tasksCard.unassignedTasks" />}
            variant={
              tasksFilter === TaskStatus.UNASSIGNED ? 'filled' : 'outlined'
            }
            color="primary"
            onClick={() => {
              if (tasksFilter === TaskStatus.UNASSIGNED) {
                setTasksFilter(TaskStatus.ALL);
              } else {
                setTasksFilter(TaskStatus.UNASSIGNED);
              }
            }}
            sx={{
              borderRadius: '8px',
              '& .MuiChip-avatar': { backgroundColor: 'transparent' },
            }}
          />
          <Box flex={1} />
          <Button onClick={() => setOpenTaskForm(true)}>
            {<Trans i18nKey="tasks.addTask" />}
          </Button>
        </Grid>
        <MaterialTable
          title={<Trans i18nKey="tables.tasksCard.description" />}
          localization={localization}
          actions={[
            (a) => ({ icon: EditIcon, onClick: () => setOpenTaskForm(a._id) }),
            (a) => ({
              icon: RemoveRedEye,
              onClick: () => setOpenTaskDetail(a._id),
            }),
          ]}
          columns={dataTable.columns}
          data={tableData.data}
          components={{
            Pagination: CustomTablePagination,
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
          }}
        />
      </Paper>

      {openTaskDetail && (
        <TaskDetailDialog
          handleDelete={handleDelete}
          onClose={() => setOpenTaskDetail(undefined)}
          taskId={openTaskDetail}
        />
      )}
      {openTaskForm && (
        <TaskDialog
          taskId={typeof openTaskForm === 'string' ? openTaskForm : undefined}
          onSubmit={() => {
            setOpenTaskForm(false);
            getTasks();
          }}
          onClose={() => {
            setOpenTaskForm(false);
          }}
        />
      )}
    </div>
  );
});

export default TasksCard;
