import {
  DashboardOutlined,
  PlaceOutlined,
  List as ListIcon,
  GroupOutlined as GroupOutlinedIcon,
  Devices as DevicesIcon,
  CorporateFare as CorporateFareIcon,
  Grading as GradingIcon,
} from '@mui/icons-material';

export const menuIcons = {
  dashboard: DashboardOutlined,
  list: ListIcon,
  users: GroupOutlinedIcon,
  devices: DevicesIcon,
  building: CorporateFareIcon,
  gradingIcon: GradingIcon,
  placement: PlaceOutlined,
};

