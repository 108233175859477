import React, { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, FormControl, Grid, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { CompanyContext, HardwarePlacementContext, UserContext } from '../../contexts';
import { Company, PlacementType } from '../../commonTypes';
import { Field, Form } from 'react-final-form';
import { DatePicker } from 'mui-rff';
import { useSnackbar } from 'notistack';
import { Validations } from '../../utils';
import { useParams } from 'react-router-dom';

interface TaskFormProps {
  onSubmit: () => void;
  taskId?: string;
}

const renderPlacement = (placements: PlacementType[], companies: Company[]) => {
  const reduced = placements.reduce((acc, placement) => {
    if (!acc[placement.companyId]) {
      acc[placement.companyId] = [];
    }
    acc[placement.companyId].push(placement);

    return acc;
  }, {} as Record<string, PlacementType[]>);

  const out: ReactElement[] = [];

  Object.entries(reduced).forEach(([key, value]) => {
    out.push(
      <MenuItem disabled value="">
        <Typography fontWeight={'bolder'}>
          {companies.find((c) => c._id === key)?.companyName}
        </Typography>
      </MenuItem>,
    );

    value.forEach((placement) => {
      out.push(
        <MenuItem key={placement._id} value={placement._id}>
          {placement.name}
        </MenuItem>,
      );
    });
  });
  return out;
};
export const TaskForm: FC<TaskFormProps> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  const { globalUserCompany = [] } = useContext(CompanyContext);
  const { userData } = useContext(UserContext);
  const { globalHardwarePlacements = [] } = useContext(
    HardwarePlacementContext,
  );

  const [taskDetail, setTaskDetail] = useState({
    companyIds: [],
    placementIds: [],
  });

  const getTask = useCallback(async () => {
    if (!props.taskId) return;
    const res = await fetch(`/api/tasks/detail/${props.taskId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    });
    if (res.ok) {
      const data = await res.json();
      setTaskDetail({ ...data, assigneeId: data.assignee });
    }
  }, [props.taskId, userData]);

  useEffect(() => {
    getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (data: any) => {
    let apiUrl = '/api/tasks';
    if (props.taskId) {
      apiUrl = `/api/tasks/${props.taskId}`;
    }

    const res = await fetch(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: props.taskId ? 'PUT' : 'POST',
      body: JSON.stringify(data),
    });

    if (res.ok) {
      props.onSubmit();
      enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
        variant: 'success',
      });
    }
  };
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      validate={(v) =>
        Validations.new(v).required('deadline').required('name').getErrors()
      }
      initialValues={
        props.taskId ? taskDetail : { companyIds: id ? [id] : [], placementIds: [] }
      }
      render={({
                 handleSubmit,
                 values,
                 pristine,
               }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid
              container
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingX: '14px',
              }}
              rowGap={'12px'}
            >
              <Grid item xs={12}>
                <Field name={'name'}>
                  {(props) => (
                    <TextField
                      fullWidth
                      error={props.meta.error && props.meta.touched && props.meta.error}
                      helperText={props.meta.error && props.meta.touched && props.meta.error}
                      {...props.input}
                      label={t('tasks.name')}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item container gap={'12px'}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Field name="companyIds">
                      {(props) => {
                        const isAllSelected =
                          props.input.value.length === globalUserCompany.length;
                        return (
                          <FormControl fullWidth>
                            <Select<Array<string>>
                              {...props.input}
                              multiple
                              displayEmpty
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <em>
                                      <Trans i18nKey="tasks.companies" />
                                    </em>
                                  );
                                }
                                return selected
                                  .map(
                                    (s) =>
                                      globalUserCompany.find(
                                        (guc) => guc._id === s,
                                      )?.companyName,
                                  )
                                  .join(', ');
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  <Trans i18nKey="tasks.companies" />
                                </em>
                              </MenuItem>
                              <Button
                                fullWidth
                                onClick={() => {
                                  if (isAllSelected) {
                                    props.input.onChange([]);
                                  } else {
                                    props.input.onChange(
                                      globalUserCompany.map((guc) => guc._id),
                                    );
                                  }
                                }}
                              >
                                {isAllSelected ? (
                                  <Trans i18nKey="tasks.unselectAll" />
                                ) : (
                                  <Trans i18nKey="tasks.selectAll" />
                                )}
                              </Button>
                              {globalUserCompany.map((company) => (
                                <MenuItem key={company._id} value={company._id}>
                                  {company.companyName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Box sx={{ width: '24px' }}></Box>
                    <Field name="placementIds">
                      {(props) => {
                        const isAllSelected =
                          props.input.value.length ===
                          globalHardwarePlacements.filter((placement) =>
                            values.companyIds.includes(placement.companyId),
                          ).length;
                        return (
                          <FormControl fullWidth>
                            <Select<Array<string>>
                              {...props.input}
                              multiple
                              displayEmpty
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <em>
                                      <Trans i18nKey="tasks.placements" />
                                    </em>
                                  );
                                }
                                return selected
                                  .map(
                                    (s) =>
                                      globalHardwarePlacements.find(
                                        (guc) => guc._id === s,
                                      )?.name,
                                  )
                                  .join(', ');
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  <Trans i18nKey="tasks.placements" />
                                </em>
                              </MenuItem>
                              <Button
                                fullWidth
                                onClick={() => {
                                  if (isAllSelected) {
                                    props.input.onChange([]);
                                  } else {
                                    props.input.onChange(
                                      globalHardwarePlacements
                                        .filter((placement) =>
                                          values.companyIds.includes(
                                            placement.companyId,
                                          ),
                                        )
                                        .map((ghp) => ghp._id),
                                    );
                                  }
                                }}
                              >
                                {isAllSelected ? (
                                  <Trans i18nKey="tasks.unselectAll" />
                                ) : (
                                  <Trans i18nKey="tasks.selectAll" />
                                )}
                              </Button>
                              {renderPlacement(
                                globalHardwarePlacements.filter((placement) =>
                                  values.companyIds.includes(
                                    placement.companyId,
                                  ),
                                ),
                                globalUserCompany,
                              )}
                            </Select>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </Box>
                </Grid>
              </Grid>
              <Grid item container columnGap={'12px'}>
                <Grid item xs>
                  <Box display="flex" alignItems="flex-start">
                    <Field name="assigneeId">
                      {(props) => {
                        return (
                          <FormControl fullWidth>
                            <Select<string>
                              {...props.input}
                              displayEmpty
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <em>
                                      <Trans i18nKey="tasks.assignee" />
                                    </em>
                                  );
                                }
                                return userData?.usersData.find(
                                  (c) => c._id === selected,
                                )?.email;
                              }}
                            >
                              <MenuItem disabled value="">
                                <em>
                                  <Trans i18nKey="tasks.assignee" />
                                </em>
                              </MenuItem>
                              {userData?.usersData.map((user) => (
                                <MenuItem key={user._id} value={user._id}>
                                  {user.email}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Box sx={{ width: '24px' }}></Box>
                    <DatePicker
                      inputFormat="dd.MM.yyyy"
                      name="deadline"
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Field name="description">
                  {(props) => (
                    <TextField
                      size="small"
                      multiline
                      placeholder={t('tasks.description') as string}
                      inputProps={{
                        style: {
                          height: '98px',
                          overflow: 'visible',
                        },
                      }}
                      fullWidth
                      {...props.input}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: '8px', padding: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  boxShadow: 'none',
                  width: '100%',
                }}
              >
                <Trans i18nKey="tasks.save" />
              </Button>
            </Box>
          </form>
        );
      }}
    />
  );
};
