import React, { FC, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Form } from 'react-final-form';
import { addDays } from 'date-fns';
import { Grid, Button } from '@mui/material';

import { EmployeesContext, CompanyContext } from '../contexts';
import { Hardware, HardwareRevisionType, HardwareType } from '../commonTypes';
import { isServerLicense, Validations } from '../utils';
import { EditHwFormFields } from './EditHwFormFields';
import { useUpdateHardware } from 'src/api/useUpdateHardware';

interface EditHwDialogProps {
  values?: Hardware;
  onCancel?: () => void;
  onSubmit: (hw: Hardware) => void;
  editKey?: string;
}

const validate = (formValues: Hardware) => {
  const validations = Validations.new(formValues)
    .required('type')
    .required('description');

  const { type = {} as HardwareType } = formValues;

  if (type.isDateOfPurchaseRequired) {
    validations.required('dateOfPurchase');
  }

  if (type.isGuaranteeRequired) {
    validations.required('guaranteeUntil');
  }

  if (type.isInventoryNumberRequired) {
    validations.required('inventoryNumber');
  }

  if (type.isSerialNumberRequired) {
    validations.required('serialNumber');
  }

  if (type.isPriceRequired) {
    validations.required('price');
  }

  return validations.getErrors();
};

export const EditHwForm: FC<EditHwDialogProps> = (props) => {
  const { onCancel, values, editKey } = props;
  const { globalUserCompany = [] } = useContext(CompanyContext);
  const { handleUpdateHardware } = useUpdateHardware();
  const { employees = [] } = useContext(EmployeesContext);

  const handleRevisionDate = async (
    valuesForm: Hardware,
    revision: HardwareRevisionType | null,
  ) => {
    if (values) {
      let date = new Date();

      const lastPassedRevision = values.revisionHistory.slice(-1);

      if (lastPassedRevision.length > 0) {
        const lastPassedRevisionDate = lastPassedRevision[0].completedRevision;
        date = new Date(lastPassedRevisionDate);
      } else if (values.dateOfPurchase) {
        date = new Date(values.dateOfPurchase);
      }

      let purchaseDate = date && new Date(date).getTime();
      var numberOfDaysToAdd = revision?.days;

      if (numberOfDaysToAdd && purchaseDate) {
        valuesForm.revisionNext?.setTime(
          addDays(purchaseDate, numberOfDaysToAdd).getTime(),
        );
      }
    }
  };

  const handleSubmit = async (formValues: Hardware) => {
    if (!values) {
      return;
    }

    const data = {
      ...values,
      description: formValues.description,
      price: formValues.price ? formValues.price : 0,
      serialNumber: formValues.serialNumber ? formValues.serialNumber : '--',
      dateOfPurchase: formValues.dateOfPurchase,
      guaranteeUntil: formValues.guaranteeUntil,
      revisionType: formValues.revisionType,
      placement: formValues.placement,
      count: formValues.count,
      countUnit: formValues.countUnit,
      revisionNext: formValues.revisionNext,
    };
    handleUpdateHardware(data, editKey);

    onCancel?.();
  };

  let employeesOptions: { [key: string]: string } = {};

  if (employees?.length) {
    employees.forEach((em) => {
      if (em?._id) employeesOptions[em._id] = em.firstName + ' ' + em.lastName;
    });
  }

  let foundEmployee;
  if (values) {
    foundEmployee = values.borrowedBy && employeesOptions[values.borrowedBy];
  }

  const initialValues: Hardware = {
    ...values,
    revisionHistory: values?.revisionHistory ?? [],
    revisionDate: values?.revisionDate ?? '--',
    rentalHistory: values?.rentalHistory ?? [],
    type: values?.type ?? ('--' as unknown as HardwareType),
    description: values?.description ?? '--',
    borrowedDate: values?.borrowedDate,
    revisionType: values?.revisionType,
    dateOfPurchase: values?.dateOfPurchase ?? new Date(),
    revisionNext: values?.revisionNext
      ? new Date(values?.revisionNext)
      : new Date(),
    guaranteeUntil: values?.guaranteeUntil ?? new Date(),
    inventoryNumber: values?.inventoryNumber ?? '--',
    serialNumber: values?.serialNumber ?? '--',
    price: values?.price ?? 0,
    borrowedBy: foundEmployee ?? '--',
    companyId: values?.companyId ?? '--',
    placement: values?.placement ?? null,
    borrowingConfirmed: Boolean(values?.borrowingConfirmed),
    count: values?.count,
    countUnit: values?.countUnit,
  };

  const premium = useMemo(() => {
    const foundCompany = globalUserCompany.find(
      (comp) => comp._id === values?.companyId,
    );

    let isPremium = false;
    if (foundCompany) {
      const currDate = new Date();
      const premiumValidTo = new Date(foundCompany.licenceValidTo);
      if (foundCompany.licenceType === 'premium' && premiumValidTo > currDate) {
        isPremium = true;
      } else {
        isPremium = false;
      }
    }
    if (isServerLicense) return true;
    return isPremium;
  }, [globalUserCompany, values]);

  const getCompanyVATincluded = (companyId: string) => {
    const foundCompany = globalUserCompany.find((guc) => guc._id === companyId);

    return foundCompany?.VATincluded;
  };

  return (
    <Form
      keepDirtyOnReinitialize
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, values, pristine }) => {
        const isCompanyVATincluded = getCompanyVATincluded(values.companyId!);
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid>
              <EditHwFormFields
                formValues={values}
                isPremium={premium}
                isCompanyVATincluded={isCompanyVATincluded}
                handleRevisionDate={handleRevisionDate}
              />
              <Grid
                container
                justifyContent="center"
                xs={12}
                sx={{
                  width: '100%',
                  position: 'sticky',
                  left: '0px',
                  bottom: '-18px',
                  padding: '16px 0px',
                  background: 'white',
                }}
              >
                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={pristine || submitting}
                    style={{
                      boxShadow: 'none',
                      width: '100%',
                    }}
                  >
                    <Trans i18nKey="editHwForm.save" />
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    type="button"
                    variant="text"
                    onClick={onCancel}
                    style={{
                      boxShadow: 'none',
                      width: '100%',
                    }}
                  >
                    <Trans i18nKey="editHwForm.cancel" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
