import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { AddFileForm } from './AddFileForm';
import { Dialog } from '../components';

interface AddFileDialogProps {
  onClose: () => void;
  hwId?: string;
  companyId?: string;
  refresh: () => void;
}

export const AddFileDialog: FC<AddFileDialogProps> = (props) => {
  const { onClose, hwId, companyId, refresh } = props;

  return (
    <Dialog title={<Trans i18nKey="addFileDialog.title" />} onClose={onClose}>
      <AddFileForm
        onCancel={onClose!}
        hwId={hwId}
        companyId={companyId}
        refresh={refresh!}
      />
    </Dialog>
  );
};
