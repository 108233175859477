import React, { FC, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';

import { FormBase } from './FormBase';
import { UserContext } from '../contexts';
import { Validations } from '../utils';
import { FormFields } from '../commonTypes';
import { Trans } from '../i18n';

interface FormData {
  email?: string;
  password?: string;
}

const initialValues: FormData = {
  email: '',
  password: '',
};

const formFields: FormFields[] = [
  {
    size: 12,
    field: (
      <TextField
        label={<Trans i18nKey="labels.email" />}
        type="email"
        name="email"
        variant="outlined"
        required
        inputProps={{ 'data-testid': 'login-email-input' }}
      />
    ),
  },
  {
    size: 12,
    field: (
      <TextField
        type="password"
        label={<Trans i18nKey="labels.password" />}
        name="password"
        variant="outlined"
        required
        data-testid="login-password-field"
        inputProps={{ 'data-testid': 'login-password-input' }}
      />
    ),
  },
];

const validate = (values: FormData) => {
  const errors = Validations.new(values)
    .required('email')
    .required('password')
    .emailShape('email')
    .getErrors();

  return errors;
};

interface LoginDialogProps {
  onClose?: () => void;
}

const LoginFormComponent: FC<RouteComponentProps & LoginDialogProps> = (
  props,
) => {
  const { history, onClose } = props;

  const { setUser, setLanguage, refreshUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const { location } = history;
  const { pathname } = location;

  const onSubmit = async (values: FormData) => {
    const res = await fetch('/api/users/user/login', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(values),
    }).then((data) => data.json());
    if (res.user && res.user.token) {
      setUser!(res);
      refreshUser!();

      if (res.userInfo.preferredLanguage) {
        setLanguage?.(res.userInfo.preferredLanguage);
      }

      if (onClose) {
        onClose();
      }
      if (pathname === '/') {
        history.push('/dashboard');
      }
    } else {
      enqueueSnackbar(res.error, { variant: 'error' });
    }
  };

  return (
    <>
      <FormBase
        fields={formFields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        type="login"
      />
    </>
  );
};

export const LoginForm = withRouter(LoginFormComponent);
