import React, { FC, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import { AppLayout, PrivateRoute } from './components';
import { isServerLicense } from './utils';
import ServerUsersPage from './pages/ServerUsersPage';

const EmployeePage = lazy(async () => import('./pages/EmployeePage'));
const HwTypePage = lazy(async () => import('./pages/HwTypePage'));
const HwListPage = lazy(async () => import('./pages/HwListPage'));
const ConfirmRegistrationPage = lazy(
  async () => import('./pages/ConfirmRegistrationPage')
);
const UsersPage = lazy(async () => import('./pages/UsersPage'));
const CompanyPage = lazy(async () => import('./pages/CompanyPage'));
const HomePage = lazy(async () => import('./pages/HomePage'));
const HardwareRevisionTypePage = lazy(
  async () => import('./pages/HardwareRevisionTypePage')
);
const BorrowConfirmationPage = lazy(
  async () => import('./pages/BorrowConfirmationPage')
);
const RentedConfirmationPage = lazy(
  async () => import('./pages/RentedConfirmationPage')
);
const HardwarePlacementPage = lazy(
  async () => import('./pages/HardwarePlacementPage')
);
const GuidePage = lazy(async () => import('./pages/GuidePage'));
const PlacementHardwareListPage = lazy(
  async () => import('./pages/PlacementHardwareListPage')
);
const DashboardPage = lazy(async () => import('./pages/DashboardPage'));
const CompanyCardPage = lazy(async () => import('./pages/CompanyCardPage'));

const NotFoundPage = lazy(async () => import('./pages/NotFoundPage'));

export const AppCrossroad: FC = () => {
  return (
    <Switch>
      <Route exact path="/confirm/:id">
        <AppLayout>
          <ConfirmRegistrationPage />
        </AppLayout>
      </Route>

      <Route exact path="/borrow-confirmation/:id">
        <AppLayout>
          <BorrowConfirmationPage />
        </AppLayout>
      </Route>

      <Route exact path="/inventory-confirmation/:id">
        <AppLayout>
          <RentedConfirmationPage />
        </AppLayout>
      </Route>

      <Route exact path="/hardware-placement/:id">
        <AppLayout>
          <PlacementHardwareListPage />
        </AppLayout>
      </Route>

      <Route exact path="/newpassword/:id">
        <AppLayout>
          <HomePage />
        </AppLayout>
      </Route>

      <Route path="/guide/:id">
        <AppLayout>
          <GuidePage />
        </AppLayout>
      </Route>

      <PrivateRoute exact path="/home">
        <AppLayout>
          <strong>Homepage</strong>
        </AppLayout>
      </PrivateRoute>

      <PrivateRoute exact path="/dashboard">
        <AppLayout>
          <DashboardPage />
        </AppLayout>
      </PrivateRoute>

      <Route exact path="/">
        <AppLayout>
          <HomePage />
        </AppLayout>
      </Route>

      <PrivateRoute path="/users">
        <AppLayout>
          <EmployeePage />
        </AppLayout>
      </PrivateRoute>

      <PrivateRoute path="/hardware-list">
        <AppLayout>
          <HwListPage />
        </AppLayout>
      </PrivateRoute>

      <PrivateRoute path="/hardware-list/:params">
        <AppLayout>
          <HwListPage />
        </AppLayout>
      </PrivateRoute>

      <PrivateRoute path="/hardware-type">
        <AppLayout>
          <HwTypePage />
        </AppLayout>
      </PrivateRoute>

      <PrivateRoute path="/users-list">
        <AppLayout>
          <UsersPage />
        </AppLayout>
      </PrivateRoute>

      {isServerLicense ? (
        <PrivateRoute path="/server-users-list">
          <AppLayout>
            <ServerUsersPage />
          </AppLayout>
        </PrivateRoute>
      ) : null}

      <PrivateRoute path="/companies">
        <AppLayout>
          <CompanyPage />
        </AppLayout>
      </PrivateRoute>

      <PrivateRoute path="/hardware-revision-type">
        <AppLayout>
          <HardwareRevisionTypePage />
        </AppLayout>
      </PrivateRoute>

      <PrivateRoute path="/hardware-placement">
        <AppLayout>
          <HardwarePlacementPage />
        </AppLayout>
      </PrivateRoute>

      <PrivateRoute path="/company/:id">
        <AppLayout>
          <CompanyCardPage />
        </AppLayout>
      </PrivateRoute>

      <Route>
        <AppLayout>
          <NotFoundPage />
        </AppLayout>
      </Route>
    </Switch>
  );
};
