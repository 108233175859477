import React, { FC, useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';

import { UserContext, HardwareRevisionTypeContext } from '../contexts';
import { Company, HardwareRevisionType } from '../commonTypes';
import { OwnerSelectField } from '../hardware';
import { RevisionSelectField } from '../components';
import { isServerLicense } from '../utils';

interface FormData {
  companyId?: string;
  name?: string;
  day?: number;
  revision?: number;
  enableRemindDaysBefore?: boolean;
  remindDaysBefore?: number;
}
interface NewHardwareRevisionFormProps {
  onCancel: () => void;
  onSubmit: (hardwareRevisionType: HardwareRevisionType) => void;
  selectedCompany?: string;
}

export const NewHardwareRevisionForm: FC<NewHardwareRevisionFormProps> = (
  props
) => {
  const { onSubmit, onCancel, selectedCompany } = props;

  const { userData } = useContext(UserContext);
  const { refreshHardwareRevisionType } = useContext(
    HardwareRevisionTypeContext
  );
  const { enqueueSnackbar } = useSnackbar();

  const refreshRevisions = () => {
    refreshHardwareRevisionType?.();
  };

  useEffect(() => refreshRevisions, []); // eslint-disable-line

  const initialValues: FormData = {
    companyId: selectedCompany ?? '',
    name: '',
    day: 0,
    revision: 0,
    enableRemindDaysBefore: false,
    remindDaysBefore: 1,
  };

  const handleSubmit = async (values: FormData) => {
    const shouldIncludeReminder =
      values?.enableRemindDaysBefore &&
      values?.revision &&
      values.revision !== -1 &&
      values.day;

    if (values && values?.day && values.day <= 0) {
      return enqueueSnackbar(
        <Trans i18nKey="notifications.errorDaysCannotBeLessThanZero" />,
        { variant: 'error' }
      );
    }

    if (shouldIncludeReminder) {
      const finalDays = (values.day ?? 0) * (values.revision ?? 0);

      if (values?.remindDaysBefore && values.remindDaysBefore <= 0) {
        return enqueueSnackbar(
          <Trans i18nKey="notifications.errorDaysCannotBeLessThanZero" />,
          { variant: 'error' }
        );
      }

      if (values?.remindDaysBefore && values.remindDaysBefore > 60) {
        // Maximum days before reminder
        return enqueueSnackbar(
          <Trans i18nKey="notifications.errorDaysCannotBeMoreThanSixty" />,
          { variant: 'error' }
        );
      }

      if (values?.remindDaysBefore && values.remindDaysBefore >= finalDays) {
        return enqueueSnackbar(
          <Trans i18nKey="notifications.daysMustBeGreaterThanRemindDays" />,
          { variant: 'error' }
        );
      }
    } else {
      values.remindDaysBefore = undefined;
    }

    try {
      const res = await fetch(
        '/api/hardwareRevisionTypes/hardwareRevisionType',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'POST',
          body: JSON.stringify(values),
        }
      ).then((data) => data.json());
      if (res.error) {
        return enqueueSnackbar(res.error, { variant: 'error' });
      }
      onSubmit(res);
    } catch (exception) {
      console.log(exception);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              {!isServerLicense && <Grid item xs={12}>
                <Field name="companyId">
                  {(props) => (
                    <OwnerSelectField
                      value={props.input.value}
                      onChange={(company?: Company) => {
                        props.input.onChange(company?._id);
                      }}
                      error={
                        props.meta.submitFailed ? props.meta.error : undefined
                      }
                      disabled={Boolean(selectedCompany)}
                    />
                  )}
                </Field>
              </Grid>}
              <Grid item xs={12}>
                <TextField
                  label={<Trans i18nKey="newRevisionType.revisionName" />}
                  margin="none"
                  name="name"
                  type="text"
                  fullWidth
                  required
                  inputProps={{ 'data-testid': 'revision-name-input' }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={<Trans i18nKey="newRevisionType.count" />}
                  margin="none"
                  name="day"
                  type="number"
                  fullWidth
                  required
                  inputProps={{ 'data-testid': 'revision-count-input' }}
                />
              </Grid>
              <Grid item xs={8}>
                <Field name="revision">
                  {(props) => (
                    <RevisionSelectField
                      value={props.input.value}
                      onChange={(revision?: string) => {
                        props.input.onChange(revision);
                      }}
                      error={
                        props.meta.submitFailed ? props.meta.error : undefined
                      }
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={8}>
                <Field name="enableRemindDaysBefore" type="checkbox">
                  {(props) => (
                    <FormControlLabel
                      control={<Checkbox {...props.input} />}
                      label={<Trans i18nKey="newRevisionType.shouldNotify" />}
                      disabled={
                        !Boolean(values.revision) || values.revision === -1
                      }
                    />
                  )}
                </Field>
              </Grid>

              {values.enableRemindDaysBefore && (
                <Grid item xs={8}>
                  <TextField
                    label={<Trans i18nKey="newRevisionType.remindDaysBefore" />}
                    margin="none"
                    name="remindDaysBefore"
                    type="number"
                    fullWidth
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                position={{ xs: 'sticky', lg: 'relative' }}
                bottom={{ xs: '-16px', lg: '0px' }}
                bgcolor={{ xs: 'white' }}
                sx={{
                  marginTop: '16px',
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  spacing={{ xs: 0, lg: 2 }}
                  sx={{
                    maxWidth: 'calc(100% + 32px)',
                    width: 'calc(100% + 32px)',
                    marginInline: '-16px',
                    paddingInline: '16px',
                    background: '#fff',
                  }}
                >
                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      variant="contained"
                      data-testid="submit-new-revision-btn"
                      color="primary"
                      type="submit"
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Trans i18nKey="newRevisionType.send" />
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      type="button"
                      variant="text"
                      onClick={onCancel}
                      data-testid="cancel-new-revision-btn"
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Trans i18nKey="newRevisionType.cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
