import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  SelectChangeEvent,
  InputAdornment,
} from '@mui/material';
import { SelectProps, DocumentType } from 'src/commonTypes';
import { UserContext } from 'src/contexts';

const renderItems = (
  documentTypes: DocumentType[] = [],
  locale: 'cs' | 'en' | 'bg'
) => {
  return documentTypes.map((documentType) => (
    <MenuItem key={documentType._id} value={documentType._id}>
      {documentType.locales?.[locale]}
    </MenuItem>
  ));
};

export const DocumentSelectField: FC<
  SelectProps<DocumentType | undefined> & {
    showAddNew?: boolean;
    startAdornment?: ReactNode;
  }
> = (props) => {
  const { error, value, onChange, disabled, startAdornment } = props;

  const { userData } = useContext(UserContext);
  const locale = userData?.userInfo.preferredLanguage.split('-')[0] ?? 'en';
  const [documentTypesData, setDocumentTypesData] = useState<DocumentType[]>(
    []
  );

  const documentTypes = async () => {
    const dbDocumentTypes = await fetch('/api/documents/get-document-types/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    }).then((data) => data.json());
    setDocumentTypesData(dbDocumentTypes);
  };

  useEffect(() => {
    documentTypes();
  }, []); // eslint-disable-line

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedDocumentType = documentTypesData.find(
      (documentType) => documentType._id === selectedValue
    );
    onChange(selectedDocumentType);
  };

  return (
    <FormControl error={!!error} fullWidth disabled={disabled}>
      <InputLabel>
        <Trans i18nKey="documentSelectField.documentType" />
      </InputLabel>
      <Select
        name="documentType"
        value={(value ?? -1) as string}
        onChange={handleChange}
        fullWidth
        margin="none"
        label={<Trans i18nKey="documentSelectField.documentType" />}
        required
        inputProps={{ value: value ?? '' }}
        open={isOpen}
        onClick={() => !disabled && setIsOpen((prev) => !prev)}
        startAdornment={
          startAdornment ? (
            <InputAdornment
              disablePointerEvents
              position="start"
              sx={{ display: 'flex' }}
            >
              {startAdornment}
            </InputAdornment>
          ) : null
        }
      >
        <MenuItem value={-1} onChange={() => onChange(undefined)}>
          <Trans i18nKey="labels.select" />
        </MenuItem>
        {renderItems(documentTypesData, locale as 'cs' | 'en' | 'bg')}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
