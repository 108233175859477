import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Dialog } from '../Dialog';
import EventDetailForm from '../DialogForms/EventDetailForm';

interface GuideDetailDialogProps {
  eventId: string;
  onClose: () => void;
  handleDelete?: (id: string) => void;
}

export const EventDetailDialog: FC<GuideDetailDialogProps> = (props) => {
  const { eventId, onClose, handleDelete } = props;

  return (
    <Dialog
      onClose={onClose}
      title={
        eventId ? (
          <Trans i18nKey="events.detail" />
        ) : (
          <Trans i18nKey="events.newEvent" />
        )
      }
    >
      <EventDetailForm
        eventId={eventId}
        onClose={onClose}
        handleDelete={handleDelete}
      />
    </Dialog>
  );
};
