import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Dialog } from '../Dialog';
import TaskDetailForm from '../DialogForms/TaskDetailForm';

interface GuideDetailDialogProps {
  taskId: string;
  onClose: () => void;
  handleDelete?: (id: string) => void;
}

export const TaskDetailDialog: FC<GuideDetailDialogProps> = (props) => {
  const { taskId, onClose, handleDelete } = props;

  return (
    <Dialog
      onClose={onClose}
      title={
        taskId ? (
          <Trans i18nKey="tasks.detail" />
        ) : (
          <Trans i18nKey="tasks.newTask" />
        )
      }
    >
      <TaskDetailForm handleDelete={handleDelete} taskId={taskId} onClose={onClose} />
    </Dialog>
  );
};
