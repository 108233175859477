import React, { FC, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { pick } from 'lodash';
import {
  Grid,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import { Form, Field } from 'react-final-form';

import { AddNewUserCheckbox } from './AddNewUserCheckbox';
import { UserContext, CompanyContext, UsersAppRoleEnum } from '../contexts';
import { Company, NewUserCompany } from '../commonTypes';
import { LANGUAGES, isServerLicense } from '../utils';

interface AddNewUserFormProps {
  onCancel: () => void;
  onSubmit: (company: Company) => void;
}

interface FormData {
  email?: string;
  companies?: Array<NewUserCompany>;
  preferredLanguage?: string;
  appRole?: string;
}

export const AddNewUserForm: FC<AddNewUserFormProps> = (props) => {
  const { onCancel, onSubmit } = props;

  const { globalAdminCompany = [], refreshCompany } =
    useContext(CompanyContext);
  const { userData, checkRole, refreshUser } = useContext(UserContext);

  const [language, setLanguage] = useState(
    userData?.userInfo.preferredLanguage
  );

  const [appRole, setAppRole] = useState('user');

  const { enqueueSnackbar } = useSnackbar();

  const handleLang = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value);
  };

  const handleRole = (event: SelectChangeEvent<string>) => {
    setAppRole(event.target.value);
  };

  useEffect(() => {
    refreshCompany?.();
  }, []); // eslint-disable-line

  const pathName = window.location.pathname;
  const isServerPage = pathName.includes('server');

  const handleSubmit = async (values: FormData) => {
    values.preferredLanguage = language;
    if (isServerLicense) {
      values.appRole = appRole;
    }

    if (!values.email) {
      enqueueSnackbar(<Trans i18nKey="notifications.fillInTheEmail" />, {
        variant: 'error',
      });
      return;
    }

    if (!isServerLicense && !values.companies?.filter(Boolean).length) {
      enqueueSnackbar(<Trans i18nKey="notifications.companyNotSelected" />, {
        variant: 'error',
      });
      return;
    }

    try {
      if (isServerLicense && isServerPage) {
        const res = await fetch('/api/users/user/server-license/create-user', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'POST',
          body: JSON.stringify({
            ...pick(values, ['email', 'preferredLanguage', 'appRole']),
          }),
        }).then((data) => data.json());
        if (res.error) {
          enqueueSnackbar(`${res.error}`, { variant: 'error' });
          return;
        }
        onSubmit(res);
        enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
          variant: 'success',
        });
        refreshUser?.();
      } else {
        const res = await fetch('/api/users/user/createUser', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'POST',
          body: JSON.stringify({
            ...pick(values, ['email', 'companies', 'preferredLanguage']),
          }),
        }).then((data) => data.json());
        if (res.error) {
          enqueueSnackbar(`${res.error}`, { variant: 'error' });
          return;
        }
        onSubmit(res);
        enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
          variant: 'success',
        });
      }
    } catch (error) {}
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} data-testid="add-new-user-form">
            <Grid spacing={2} container>
              <Grid item xs={12}>
                <TextField
                  label={<Trans i18nKey="usersForm.email" />}
                  name="email"
                  type="text"
                  inputProps={{ 'data-testid': 'user-email-input' }}
                />
              </Grid>

              <Grid item xs={12}>
                <Select
                  fullWidth
                  label={<Trans i18nKey="usersForm.language" />}
                  name="preferredLanguage"
                  defaultValue={language}
                  onChange={handleLang}
                >
                  {LANGUAGES.map((lang) => (
                    <MenuItem key={lang.id} value={lang.id}>
                      {lang.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {isServerLicense &&
              checkRole(UsersAppRoleEnum.ADMIN) &&
              isServerPage ? (
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    label="appRole"
                    name="appRole"
                    defaultValue="user"
                    onChange={handleRole}
                  >
                    <MenuItem key="admin" value="admin">
                      <Trans i18nKey="usersForm.admin" />
                    </MenuItem>
                    <MenuItem key="editor" value="editor">
                      <Trans i18nKey="usersForm.editor" />
                    </MenuItem>
                    <MenuItem key="user" value="user">
                      <Trans i18nKey="usersForm.user" />
                    </MenuItem>
                  </Select>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Grid item xs={12} display="flex">
                      <Grid item xs={12}>
                        {globalAdminCompany?.map((item, idx) => {
                          return (
                            <Field name={`companies[${idx}]`}>
                              {(props) => (
                                <AddNewUserCheckbox
                                  name={props.input.name}
                                  onChange={(company?: NewUserCompany) => {
                                    props.input.onChange(company);
                                  }}
                                  company={item}
                                  key={idx}
                                />
                              )}
                            </Field>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Grid item xs={12} sm={4.6}>
                      <Grid>
                        <strong>
                          <Trans i18nKey="usersForm.admin" />:
                        </strong>
                        <Trans i18nKey="usersForm.adminDescription" />
                      </Grid>
                      <Grid>
                        <strong>
                          <Trans i18nKey="usersForm.editor" />:
                        </strong>
                        <Trans i18nKey="usersForm.editorDescription" />
                      </Grid>
                      <Grid>
                        <strong>
                          <Trans i18nKey="usersForm.user" />:
                        </strong>
                        <Trans i18nKey="usersForm.userDescription" />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
                position={{ xs: 'sticky', lg: 'relative' }}
                bottom={{ xs: '-16px' }}
                bgcolor={{ xs: 'white' }}
                sx={{
                  marginTop: '16px',
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  spacing={{ xs: 0, lg: 2 }}
                  sx={{
                    maxWidth: 'calc(100% + 32px)',
                    width: 'calc(100% + 32px)',
                    marginInline: '-16px',
                    paddingInline: '16px',
                    paddingBottom: '16px',
                    background: '#fff',
                  }}
                >
                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      data-testid="submit-new-user-btn"
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Trans i18nKey="labels.send" />
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      type="button"
                      variant="text"
                      onClick={onCancel}
                      data-testid="cancel-new-app-user-btn"
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                    >
                      <Trans i18nKey="labels.cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
