import React, { FC, useRef } from 'react';
import { Trans } from 'react-i18next';

import { FileForm, FileFormFunctions } from './FileForm';
import { Dialog } from '../components';
import { Button, Grid } from '@mui/material';

interface FileDialogProps {
  onClose: () => void;
  hwId?: string;
}

export const FileDialog: FC<FileDialogProps> = (props) => {
  const { onClose, hwId } = props;
  const filesRef = useRef<FileFormFunctions>(null);
  return (
    <Dialog title={<Trans i18nKey="fileDialog.title" />} onClose={onClose}>
      <FileForm hwId={hwId} ref={filesRef} />
      <Grid
        item
        xs={12}
        position={{ xs: 'sticky', lg: 'relative' }}
        bottom={{ xs: '-16px' }}
        bgcolor={{ xs: 'white' }}
        sx={{
          marginTop: '16px',
        }}
      >
        <Grid
          container
          justifyContent="center"
          sx={{
            maxWidth: 'calc(100% + 32px)',
            width: 'calc(100% + 32px)',
            marginInline: '-16px',
            paddingInline: '16px',
            background: '#fff',
          }}
        >
          <Grid item xs={12}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => filesRef.current?.openAddFileDialog()}
              data-testid="new-doc-btn"
            >
              <Trans i18nKey="fileForm.newDocument" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="button"
              fullWidth
              onClick={onClose}
              data-testid="close-add-file-modal-btn"
            >
              <Trans i18nKey="fileForm.close" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
